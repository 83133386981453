import * as Yup from "yup"
import { IFormValues } from "./initialValue"

const validationSchema: Yup.ObjectSchema<IFormValues> = Yup.object({
  _id: Yup.string().required("Bokun ID is required (_id)"),
  bokun_product_code: Yup.string()
    .required("Bokun ID is required")
    .matches(
      /^\d{6}$/,
      "Bokun ID must be 6 numbers, no letters or special characters"
    ),
  title: Yup.string().required("Product Identifier is required"),
  platform_product_name: Yup.string().required("Platform title is required"),
  guide_assignment_identifier: Yup.string().required(
    "Guide assignment identifier is required"
  ),
  product_short_description: Yup.string()
    .required("Short description is required")
    .min(20, "Short description must be at least 20 characters")
    .max(250, "Short description must be at most 250 characters"),
  product_full_description: Yup.string()
    .required("Full description is required")
    .min(200, "Full description must be at least 200 characters"),
  pricing_options: Yup.array().min(
    1,
    "At least one pricing option is required"
  ),
  options: Yup.array()
    .of(
      Yup.object().shape({
        title: Yup.string().required("Option title is required"),
        bokun_code: Yup.string()
          .required("Bokun id is required")
          .matches(
            /^\d{7}$/,
            "Bokun id must be 7 numbers, no letters or special characters"
          ),
        is_private: Yup.boolean().required("Private option is required"),
        is_guided: Yup.boolean().required("Guided option is required"),
        pickup_included: Yup.boolean().required(
          "Pickup included option is required"
        ),
        requires_vehicle: Yup.boolean().required(
          "Requires vehicle option is required"
        ),
        requires_platform_entry: Yup.boolean().required(
          "Requires platform entry option is required"
        ),
        description: Yup.string().required("Option description is required"),
        meeting_point_id: Yup.string().notRequired(),
      })
    )
    .min(1, "At least one option is required"),
  start_times: Yup.array()
    .of(
      Yup.object().shape({
        time_slot: Yup.string().required("Start time is required"),
        label: Yup.string().required("Start time label is required"),
        isDefaultPickupTime: Yup.boolean().required(
          "Default pickup time is required"
        ),
        bokun_start_time_id: Yup.string()
          .required("Bokun start time id is required")
          .matches(
            /^\d{7}$/,
            "Bokun id must be 7 numbers, no letters or special characters"
          ),
      })
    )
    .min(1, "At least one start time is required"),
  tour_types: Yup.array().min(1, "At least one tour type is required"),
  tour_categories: Yup.array().min(1, "At least one tour category is required"),
  activity_level: Yup.string().required("Activity level is required"),
  crewGroups: Yup.array().min(1, "At least one crew group is required"),
  crewRoles: Yup.array().min(1, "At least one crew role is required"),
  additional_info: Yup.array().of(
    Yup.string()
      .required("Additional info cannot be empty")
      .min(10, "Each additional info must be at least 10 characters")
  ),
  highlights: Yup.array().of(
    Yup.string()
      .required("Highlight item cannot be empty")
      .min(2, "Each highlight item must be at least 2 characters")
  ),
  special_instructions: Yup.array().of(
    Yup.string()
      .required("Special instruction cannot be empty")
      .min(10, "Each special instruction must be at least 10 characters")
  ),
  inclusions: Yup.array().of(
    Yup.string()
      .required("Inclusion item cannot be empty")
      .min(2, "Each inclusion item must be at least 2 characters")
  ),
  exclusions: Yup.array().of(
    Yup.string()
      .required("Exclusion item cannot be empty")
      .min(2, "Each exclusion item must be at least 2 characters")
  ),
  destinations: Yup.array().of(
    Yup.string()
      .required("Destination item cannot be empty")
      .min(2, "Each destination item must be at least 2 characters")
  ),
  tour_duration: Yup.string().required("Tour duration is required"),
  tour_duration_type: Yup.string().required("Tour duration type is required"),
  review_link: Yup.string()
    .url("Invalid review link URL")
    .required("Review link is required"),
  affiliate_link: Yup.string().url("Invalid affiliate link URL"),
  market_price: Yup.number().required("Market price is required"),
  location: Yup.object().shape({
    address: Yup.string().required("Address is required"),
    latitude: Yup.number().required("Latitude is required"),
    longitude: Yup.number().required("Longitude is required"),
  }),
  product_pictures: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required("File ID is required"),
        url: Yup.string().required("Invalid product picture URL"),
        alt: Yup.string().required("Alt text is required"),
        description: Yup.string().required("Description is required"),
        caption: Yup.string().required("Caption is required"),
      })
    )
    .min(1, "At least one product picture is required"),
  isAvailableInPlan: Yup.boolean().required("Is available in plan is required"),
  //index: Yup.number().required("Position index is required"),
  isPublished: Yup.boolean().required("Is published is required"),
}) as Yup.ObjectSchema<IFormValues>

export default validationSchema
