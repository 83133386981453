import { useState, useEffect, createContext, lazy, Suspense } from "react"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"
import { RotatingLines } from "react-loader-spinner"
import {
  useAddNewProduct,
  useCloneProduct,
  useUpdateProduct,
  useDeleteProduct,
} from "../../../../../../../reactQueryHooks"
import ItemsList from "./ItemsList"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import validationSchema from "./yupValidationSchema"
import { initialValues, IFormValues } from "./initialValue"

const BokunProductCodeField = lazy(
  () => import("../form_fields/BokunProductCodeField")
)
const ProductIdentifierField = lazy(
  () => import("../form_fields/ProductIdentifierField")
)
const PlatformTitleField = lazy(
  () => import("../form_fields/PlatformTitleField")
)
const GuideAssignmentIdentifierField = lazy(
  () => import("../form_fields/GuideAssignmentIdentifierField")
)
const ProductShortDescriptionField = lazy(
  () => import("../form_fields/ProductShortDescriptionField")
)
const ProductFullDescriptionField = lazy(
  () => import("../form_fields/ProductFullDescriptionField")
)
const PricingOptionsField = lazy(
  () => import("../form_fields/PricingTypesField")
)
const OptionsField = lazy(() => import("../form_fields/OptionsField"))
const TimeSlotsField = lazy(() => import("../form_fields/TimeSlotsField"))
const TourTypesField = lazy(() => import("../form_fields/TourTypesField"))
const TourCategoriesField = lazy(
  () => import("../form_fields/TourCategoriesField")
)
const ActivityLevelField = lazy(
  () => import("../form_fields/ActivityLevelField")
)
const CrewGroupsField = lazy(() => import("../form_fields/CrewGroupsField"))
const CrewRolesField = lazy(() => import("../form_fields/CrewRolesField"))
const AdditionalInfoField = lazy(
  () => import("../form_fields/AdditionalInfoField")
)
const HighlightsField = lazy(() => import("../form_fields/HighlightsField"))
const SpecialInstructionsField = lazy(
  () => import("../form_fields/SpecialInstructionsField")
)
const InclusionsField = lazy(() => import("../form_fields/InclusionsField"))
const ExclusionsField = lazy(() => import("../form_fields/ExclusionsField"))
const DestinationsField = lazy(() => import("../form_fields/DestinationsField"))

const TourDurationField = lazy(() => import("../form_fields/TourDurationField"))
const TourDurationTypeField = lazy(
  () => import("../form_fields/TourDurationTypeField")
)
const ReviewLinkField = lazy(() => import("../form_fields/ReviewLinkField"))
const AffiliateLinkField = lazy(
  () => import("../form_fields/AffiliateLinkField")
)
const MarketPriceField = lazy(() => import("../form_fields/MarketPriceField"))
const LocationField = lazy(() => import("../form_fields/LocationField"))
const ProductPicturesField = lazy(
  () => import("../form_fields/ProductPicturesField")
)
const SettingsField = lazy(() => import("../form_fields/SettingsField"))

//---------------------------------------------------------

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  input {
    font-size: 13px;
  }
  //target all input placeholders
  input::placeholder {
    font-size: 10px;
  }
`
const InnerContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const TopSelectorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
  padding-top: 5px;
`

const ItemContentContainer = styled(motion.div)`
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  overflow: hidden;
`

const BottomButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`

const ErrorMessageContainer = styled(motion.div)`
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 13px;
  color: indianred;
  font-weight: 600;
  i {
    font-size: 13px;
  }
`

//---------------------------------------------------------

export const list: Array<{ title: string; field_name: keyof IFormValues }> = [
  {
    title: "1. Bokun ID",
    field_name: "bokun_product_code",
  },
  {
    title: "2. Product Identifier",
    field_name: "title",
  },
  {
    title: "3. Platform Product Title",
    field_name: "platform_product_name",
  },
  {
    title: "4. Guide Assignment Identifier",
    field_name: "guide_assignment_identifier",
  },
  {
    title: "5. Product Short Description",
    field_name: "product_short_description",
  },
  {
    title: "6. Product Full Description",
    field_name: "product_full_description",
  },
  {
    title: "7. Pricing Types",
    field_name: "pricing_options",
  },
  {
    title: "8. Options",
    field_name: "options",
  },
  {
    title: "9. Start Times",
    field_name: "start_times",
  },
  {
    title: "10. Tour Types",
    field_name: "tour_types",
  },
  {
    title: "11. Tour Categories",
    field_name: "tour_categories",
  },
  {
    title: "12. Activity Level",
    field_name: "activity_level",
  },
  {
    title: "13. Crew Groups",
    field_name: "crewGroups",
  },
  {
    title: "14. Crew Roles",
    field_name: "crewRoles",
  },
  {
    title: "15. Additional Info",
    field_name: "additional_info",
  },
  {
    title: "16. Highlights",
    field_name: "highlights",
  },
  {
    title: "17. Special Instructions",
    field_name: "special_instructions",
  },
  {
    title: "18. Inclusions",
    field_name: "inclusions",
  },
  {
    title: "19. Exclusions",
    field_name: "exclusions",
  },
  {
    title: "20. Destinations",
    field_name: "destinations",
  },
  {
    title: "21. Tour Duration",
    field_name: "tour_duration",
  },
  {
    title: "22. Tour Duration Type",
    field_name: "tour_duration_type",
  },
  {
    title: "23. Review Link",
    field_name: "review_link",
  },
  {
    title: "24. Affiliate Product Link",
    field_name: "affiliate_link",
  },
  {
    title: "25. Market Price",
    field_name: "market_price",
  },
  {
    title: "26. Location",
    field_name: "location",
  },
  {
    title: "27. Product Pictures",
    field_name: "product_pictures",
  },
  {
    title: "28. Product Settings",
    field_name: "settings",
  },
]

//---------------------------------------------------------

//---------------------------------------------------------

export const formContext = createContext({
  step: 1,
  setStep: (step: number) => {},
  action: "next",
  handleChangeStep: (action: "next" | "prev") => {},
});

//---------------------------------------------------------

const FormContainer = ({ productData }: { productData: any }) => {
  const initialFormValues = initialValues(productData);
  const {
    bokun_product_code: initialBokunProductCode,
    title: initialTitle,
    platform_product_name: initialPlatformProductName,
    guide_assignment_identifier: initialGuideAssignmentIdentifier,
    product_short_description: initialProductShortDescription,
    product_full_description: initialProductFullDescription,
    pricing_options: initialPricingOptions,
    options: initialOptions,
    start_times: initialTimeSlotsWithRange,
    tour_types: initialTourTypes,
    tour_categories: initialTourCategories,
    activity_level: initialActivityLevel,
    crewGroups: initialCrewGroups,
    crewRoles: initialCrewRoles,
    additional_info: initialAdditionalInfo,
    highlights: initialHighlights,
    special_instructions: initialSpecialInstructions,
    inclusions: initialInclusions,
    exclusions: initialExclusions,
    destinations: initialDestinations,
    tour_duration: initialTourDuration,
    tour_duration_type: initialTourDurationType,
    review_link: initialReviewLink,
    affiliate_link: initialAffiliateLink,
    market_price: initialMarketPrice,
    location: initialLocation,
    product_pictures: initialProductPictures,
  } = initialFormValues;
  const [step, setStep] = useState(1);
  const [action, setAction] = useState<"next" | "prev">("next");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showItemsList, setShowItemsList] = useState(true);

  const methods = useForm<IFormValues>({
    defaultValues: initialFormValues,
    resolver: yupResolver(validationSchema),
  });
  const {
    reset,
    trigger,
    formState: { errors, isValid },
  } = methods;

  useEffect(() => {
    const validateFormOnMount = async () => {
      await trigger();
    };
    validateFormOnMount();
  }, [trigger]);

  const handleChangeStep = async (action: "next" | "prev") => {
    setAction(action);

    const isIdValid = await trigger("_id");
    const isBokunCodeValid = await trigger("_id");

    if (step === 1 && !isIdValid && !isBokunCodeValid) {
      alert("You have to provide a Bokun ID first");
      return;
    }

    if (action === "prev") {
      if (step === 1) {
        // If at the first step, wrap around to the last step
        setStep(list.length);
      } else {
        setStep(step - 1); // Move to the previous step
      }
      return;
    }
    // Check if moving next
    if (action === "next") {
      if (step === list.length) {
        // If at the last step, wrap around to the first step
        setStep(1);
      } else {
        setStep(step + 1); // Move to the next step
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    setShowItemsList(screenWidth >= 650); // Update the state based on screen width
  }, [screenWidth]);

  const { mutateAsync: addNewProduct, isLoading: isAddingProduct } =
    useAddNewProduct() as any;

  const { mutateAsync: cloneProduct } = useCloneProduct() as any;

  const { mutateAsync: updateProduct, isLoading: isUpdatingProduct } =
    useUpdateProduct() as any;

  const { mutateAsync: deleteProduct } = useDeleteProduct() as any;

  return (
    <FormProvider {...methods}>
      <ContentContainer>
        <TopSelectorContainer>
          <IconButton
            sx={{
              backgroundColor: "white",
              "&:hover": { backgroundColor: "white" },
            }}
            aria-label="delete"
            size="medium"
            onClick={() => handleChangeStep("prev")}
          >
            <ChevronLeftIcon fontSize="inherit" />
          </IconButton>
          <FormControl
            sx={{
              width: screenWidth > 400 ? 270 : 200,
            }}
          >
            <InputLabel>Step</InputLabel>
            <Select
              value={step}
              label="Step"
              onChange={async (e) => {
                const isIdValid = await trigger("_id");
                const isBokunCodeValid = await trigger("_id");
                if (step === 1 && !isIdValid && !isBokunCodeValid) {
                  alert("You have to provide a Bokun ID first");
                  return;
                }

                setStep(e.target.value as number);
              }}
              sx={{
                zIndex: 1000,
                color: errors[list[step - 1]?.field_name]
                  ? "indianred"
                  : "black",
                // MuiInputLabel-animated
                "&.MuiInputLabel-root": {
                  zIndex: 1000,
                  color: "red",
                },
              }}
            >
              {list.map((item, index) => (
                <MenuItem
                  key={index}
                  value={index + 1}
                  sx={{
                    color: errors[item.field_name] ? "indianred" : "black",
                  }}
                >
                  {item.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <IconButton
            sx={{
              backgroundColor: "white",
              "&:hover": { backgroundColor: "white" },
            }}
            aria-label="delete"
            size="medium"
            onClick={() => handleChangeStep("next")}
          >
            <ChevronRightIcon fontSize="inherit" />
          </IconButton>
        </TopSelectorContainer>
        <formContext.Provider
          value={{ step, setStep, action, handleChangeStep }}
        >
          <InnerContentContainer>
            {showItemsList && <ItemsList step={step} setStep={setStep} />}
            <ItemContentContainer>
              <Suspense
                fallback={
                  <span
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <RotatingLines width="20" strokeColor="grey" />
                  </span>
                }
              >
                {step === 1 && (
                  <BokunProductCodeField
                    initialValue={initialBokunProductCode}
                  />
                )}
                {step === 2 && (
                  <ProductIdentifierField initialValue={initialTitle} />
                )}

                {step === 3 && (
                  <PlatformTitleField
                    initialValue={initialPlatformProductName}
                  />
                )}

                {step === 4 && (
                  <GuideAssignmentIdentifierField
                    initialValue={initialGuideAssignmentIdentifier}
                  />
                )}

                {step === 5 && (
                  <ProductShortDescriptionField
                    initialValue={initialProductShortDescription}
                  />
                )}

                {step === 6 && (
                  <ProductFullDescriptionField
                    initialValue={initialProductFullDescription}
                  />
                )}

                {step === 7 && (
                  <PricingOptionsField initialValue={initialPricingOptions} />
                )}

                {step === 8 && <OptionsField initialValue={initialOptions} />}

                {step === 9 && (
                  <TimeSlotsField initialValue={initialTimeSlotsWithRange} />
                )}

                {step === 10 && (
                  <TourTypesField initialValue={initialTourTypes} />
                )}

                {step === 11 && (
                  <TourCategoriesField initialValue={initialTourCategories} />
                )}

                {step === 12 && (
                  <ActivityLevelField initialValue={initialActivityLevel} />
                )}

                {step === 13 && (
                  <CrewGroupsField initialValue={initialCrewGroups} />
                )}

                {step === 14 && (
                  <CrewRolesField initialValue={initialCrewRoles} />
                )}

                {step === 15 && (
                  <AdditionalInfoField initialValue={initialAdditionalInfo} />
                )}

                {step === 16 && (
                  <HighlightsField initialValue={initialHighlights} />
                )}

                {step === 17 && (
                  <SpecialInstructionsField
                    initialValue={initialSpecialInstructions}
                  />
                )}

                {step === 18 && (
                  <InclusionsField initialValue={initialInclusions} />
                )}

                {step === 19 && (
                  <ExclusionsField initialValue={initialExclusions} />
                )}

                {step === 20 && (
                  <DestinationsField initialValue={initialDestinations} />
                )}

                {step === 21 && (
                  <TourDurationField initialValue={initialTourDuration} />
                )}

                {step === 22 && (
                  <TourDurationTypeField
                    initialValue={initialTourDurationType}
                  />
                )}

                {step === 23 && (
                  <ReviewLinkField initialValue={initialReviewLink} />
                )}

                {step === 24 && (
                  <AffiliateLinkField initialValue={initialAffiliateLink} />
                )}

                {step === 25 && (
                  <MarketPriceField initialValue={initialMarketPrice} />
                )}

                {step === 26 && (
                  <LocationField initialValue={initialLocation} />
                )}

                {step === 27 && (
                  <ProductPicturesField initialValue={initialProductPictures} />
                )}

                {step === 28 && <SettingsField />}
              </Suspense>
            </ItemContentContainer>
          </InnerContentContainer>
        </formContext.Provider>
        <BottomButtonsContainer>
          <AnimatePresence>
            {!isValid && (
              <ErrorMessageContainer
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <i className="fa-solid fa-circle-exclamation"></i>
                <span>There are errors in the form</span>
              </ErrorMessageContainer>
            )}
          </AnimatePresence>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            type="button"
            title="Reset form"
            onClick={async () => {
              reset(initialFormValues);
              await trigger();
              setStep(1);
            }}
          >
            RESET FORM
          </Button>
        </BottomButtonsContainer>
      </ContentContainer>
    </FormProvider>
  );
};

export default FormContainer
