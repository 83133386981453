import styled from "styled-components"
import { motion } from "framer-motion"
import { RotatingLines } from "react-loader-spinner"
import {
  useFetchEntities,
  useGetProduct,
} from "../../../../../../../reactQueryHooks"
import FormContainer from "./FormContainer"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"

//---------------------------------------------------------

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

const Modal = styled(motion.div)`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  padding: 10px;
  width: 90%;
  max-width: 1700px;
  height: 90%;
  background-color: #f5f5f5;
  overflow: hidden;
`

const FormContentContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const InnerContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const TopRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 10px;
  z-index: 0;
`

//---------------------------------------------------------

const ProductFormWrapperModal = ({
  close,
  id,
}: {
  close: () => void
  id: string
}) => {
  const { refetch: refetchEntities } = useFetchEntities()
  const { data: productData = {}, isFetching } = useGetProduct(id) as any
  return (
    <Wrapper>
      <Modal
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, transition: { duration: 0.1 } }}
        transition={{
          opacity: { duration: 0.2 },
        }}
      >
        <TopRow>
          <IconButton
            aria-label="delete"
            size="medium"
            onClick={async () => {
              close();
              refetchEntities();
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </TopRow>
        <FormContentContainer>
          {isFetching ? (
            <LoadingContainer>
              <RotatingLines width="20" strokeColor="grey" />
            </LoadingContainer>
          ) : (
            <InnerContentContainer>
              <FormContainer productData={productData} />
            </InnerContentContainer>
          )}
        </FormContentContainer>
      </Modal>
    </Wrapper>
  );
}

export default ProductFormWrapperModal
