import styled from "styled-components"
import ProductsList from "./ProductsList"
import { useState } from "react"
import { useAxios } from "../../../../../axiosProvider"
import { useFetchEntities } from "../../../../../reactQueryHooks"
//import AddProductsModal from "./AddProductsModal";
import ProductFormWrapperModal from "./form/wrapper/ProductFormWrapperModal"
import { AnimatePresence } from "framer-motion"
import { Button } from "@mui/material"

//-----------------------------------------------------------------------

const Container = styled.div`
  display: flex;
  flex: 1 0 300px; /* Each item takes at least 300px, can grow, won't shrink */
  max-height: 500px;
  flex-direction: column;
  gap: 7px;
  background-color: white;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  padding: 7px;
  border-radius: 5px;
  user-select: none;
  width: 100%;
  height: 100%;
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 7px;
  overflow-y: auto;
  position: relative;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: rgb(35, 139, 243);
`

const AddButtonContainer = styled.div`
  width: 100%;
  min-height: 40px;
  border-radius: 5px;
`

const AddButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #588637;
  color: white;
  font-weight: bold;
  font-size: 13px;
  border-radius: 5px;
  &:hover {
    background: #6e9b4f;
  }
`

//-----------------------------------------------------------------------

const ProductsOptions = () => {
  const { data: entities } = useFetchEntities()
  const [showAddProductsModal, setShowAddProductsModal] = useState(false)
  const [productId, setProductId] = useState(null);
  const { user } = useAxios();
  const userIsPermittedToEdit =
    user.isAdmin || (user.isModerator && user.permissions.addNewActivities);

  return (
    <Container>
      <TitleContainer>Products</TitleContainer>
      <ContentContainer>
        {entities?.data && (
          <ProductsList
            products={entities?.data?.products}
            onSelect={(productId) => {
              if (userIsPermittedToEdit) {
                setProductId(productId)
                setShowAddProductsModal(true)
              } else {
                alert("You have no permission to edit products")
              }
            }}
          />
        )}
        <AddButtonContainer>
          <Button
            sx={{
              width: "100%",
            }}
            variant="outlined"
            color="success"
            onClick={() => {
              if (userIsPermittedToEdit) {
                setShowAddProductsModal(true)
                setProductId(null)
              } else {
                alert("You have no permission to add new products")
              }
            }}
            title="Add Product"
          >
            Add Product
          </Button>
        </AddButtonContainer>
      </ContentContainer>
      <AnimatePresence>
        {showAddProductsModal && (
          <ProductFormWrapperModal
            close={() => setShowAddProductsModal(false)}
            id={productId}
          />
        )}
      </AnimatePresence>
    </Container>
  )
}

export default ProductsOptions
