import styled from "styled-components";
import SelectionBar from "../../../selection_bar/SelectionBar";
import SettingsBarContent from "../bar/SettingsBarContent";
import NotificationsOptions from "./notifications/NotificationsOptions";
import MeetingPointsOptions from "./meeting_points/MeetingPointsOptions";
import UsersOptions from "./users/UsersOptions";
import ProductsOptions from "./products/ProductsOptions";
import MessageDraftsOptions from "./message_drafts/MessageDraftsOptions";
import FilesOptions from "./files/FilesOptions"

//----------------------------------------------------------------

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  /* grid-template-columns: minmax(250px, 500px) minmax(250px, 500px) minmax(
      250px,
      500px
    );
  grid-template-rows: minmax(300px, 300px);
  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(300px, 300px) minmax(300px, 300px) minmax(
        300px,
        300px
      );
  }
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    grid-template-rows: minmax(300px, 300px) minmax(300px, 300px) minmax(
        300px,
        300px
      );
  } */
  padding: 10px;
  overflow: auto;
  gap: 10px;
  height: 100%;
`

//------------------------------------------------------------------------

const SettingsContent = () => {
  return (
    <Container>
      <SelectionBar>
        <SettingsBarContent />
      </SelectionBar>
      <ContentContainer>
        <NotificationsOptions />
        <MeetingPointsOptions />
        <UsersOptions />
        <ProductsOptions />
        <MessageDraftsOptions />
        <FilesOptions />
      </ContentContainer>
    </Container>
  )
}

export default SettingsContent;
