import { useContext } from "react";
import styled from "styled-components";
import { FilesContext } from "./FilesOptions";
import { useAxios } from "@/axiosProvider";

//---------------------------------------------------------

const FileContainer = styled.div`
  font-size: 12px;
  padding: 3px 6px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: whitesmoke;
  }
`;

//---------------------------------------------------------

const FileListItem = ({ file }: any) => {
  const { setFilesData, setFilesManagerModalShown } = useContext(FilesContext);
  const { user } = useAxios();
  const userIsAdmin = user.isAdmin;
  const userIsPermittedToUploadFiles =
    user.permissions.uploadFiles || userIsAdmin;
  return (
    <FileContainer
      onClick={() => {
        if (userIsPermittedToUploadFiles) {
          setFilesData([
            {
              ...file,
              url: `${process.env.REACT_APP_FILES_BASE_URL}${file._id}`,
            },
          ]);
          setFilesManagerModalShown(true);
        } else {
          alert("You don't have permission to add/edit files");
        }
      }}
    >
      {file.name}
    </FileContainer>
  );
};

export default FileListItem;
